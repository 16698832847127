<template>
  <div
    class="columns is-multiline main-cols has-background-primary-04 has-border-radius"
  >
    <div class="column is-12 relative p-0">
      <b-image
        src="/images/success-hero.png"
        ratio="3by1"
        class="mx-auto my-5 w-2/5"
        alt="imagen"
      />
      <div class="columns is-multiline w-full pb-4">
        <div class="column is-8 is-offset-2">
          <p class="title has-text-success font-bold mb-0 text-4xl">
            ¡Listo!
          </p>
          <p>
            Tu línea de crédito ha sido creada correctamente
          </p>
          <b-button
            type="is-success"
            class="w-3/5 mt-3 has-text-white"
            tag="router-link"
            :to="{
              name: 'detalle_linea',
              params: { idLinea: getLinea.id }
            }"
          >
            Ver la línea de crédito
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LineaFinalizado",
  computed: {
    ...mapGetters("lineascredito", ["getLinea"])
  }
};
</script>

<style lang="scss" scoped>
.img-container-absolute {
  position: relative;
  overflow: hidden;
  min-height: 40rem;
  .img-absolute {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}
</style>
