<template>
  <div class="columns is-multiline">
    <div class="column is-6">
      <b-field label="Aforo">
        <b-field>
          <b-numberinput
            controlsPosition="compact"
            type="is-light"
            placeholder="aforo..."
            v-model="garantia.aforo"
            :min="0"
            step="0.01"
          />
          <p class="control">
            <b-button type="is-light" class="cursor-default">: 1</b-button>
          </p>
        </b-field>
      </b-field>
    </div>
    <div class="column is-6">
      <valid-auto-complete
        label="Buscar y añadir garantías"
        placeholder="Buscar garantías..."
        icon="search"
        :data="garantias"
        show="descripcion"
        class="text-left"
        :clear-on-select="true"
        :clearable="true"
        @select="setGarantia"
        :expanded="true"
        @click.native="checkPersonaId"
      />
    </div>
    <div class="column is-12">
      <p class="text-lg font-bold">
        Garantías añadidas según orden de prelación
      </p>
      <p class="text-sm">
        Reordene la lista inferior arrastrando o dado click en las flechas de
        las garantías según el orden de prelación deseado.
      </p>
    </div>
    <div class="column is-12" v-if="garantia.garantias.length > 0">
      <draggable
        :list="garantia.garantias"
        class="list-group"
        group="garantias"
        ghost-class="ghost"
        @start="dragging = true"
        @end="dragging = false"
      >
        <transition-group class="w-full" name="fadeInOut" mode="out-in">
          <div
            class="card m-1 has-border-radius shadow-none p-1 list-group-item cursor-move has-background-white-ter p-3"
            v-for="(garant, index) in garantia.garantias"
            :key="garant.id"
          >
            <div class="columns">
              <div class="column text-center is-1">
                <p class="font-bold">{{ index + 1 }}°</p>
              </div>
              <div class="column text-left is-6">
                <p>{{ garant.descripcion }}</p>
              </div>
              <div class="column text-left">
                <p>ID:{{ garant.id }}</p>
              </div>
              <div class="column text-right md:pr-8">
                <b-icon
                  v-if="index > 0"
                  @click.native="goUp(index)"
                  :class="{
                    'mr-6': index == garantia.garantias.length - 1
                  }"
                  icon="chevron-up"
                  class="cursor-pointer"
                />
                <b-icon
                  v-if="index < garantia.garantias.length - 1"
                  @click.native="goDown(index)"
                  icon="chevron-down"
                  class="cursor-pointer"
                />
                <b-icon
                  icon="trash"
                  class="has-text-danger cursor-pointer"
                  @click.native="deleteGarantia(index)"
                />
              </div>
            </div>
          </div>
        </transition-group>
      </draggable>
    </div>
    <div v-else class="m-auto">
      <empty-state
        type="svg"
        :vertical="true"
        imagen="/images/garantia_emptystate.svg"
        imgclass="mb-4"
        titulo="No has añadido garantías"
        titulo-class="has-text-grey-light text-xl"
        subtitulo="Añade una arriba introduciendo el aforo y buscando la garantía"
        subtitulo-class="has-text-grey-light"
      />
    </div>
  </div>
</template>

<script>
import ValidAutoComplete from "@/components/form/ValidAutoComplete";
import EmptyState from "@/components/generals/EmptyState";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "ExtrasGarantias",
  components: {
    ValidAutoComplete,
    EmptyState,
    draggable
  },
  data() {
    return {
      dragging: false,
      garantia: {
        aforo: null,
        garantias: []
      }
    };
  },
  props: {
    personaId: Number,
  },
  methods: {
    setGarantia(val) {
      this.garantia.garantias.push(val);
    },
    checkPersonaId() {
      if (!this.personaId) {
        this.notification(
          "warning",
          "Cliente no seleccionado",
          "Primero debes seleccionar un cliente para ver sus garantías"
        );
      }
    },
    goUp(index) {
      this.dragging = true;
      let garantia_temp = JSON.parse(
        JSON.stringify(this.garantia.garantias[index - 1])
      );
      this.$set(
        this.garantia.garantias,
        index - 1,
        this.garantia.garantias[index]
      );
      this.$set(this.garantia.garantias, index, garantia_temp);
      this.dragging = false;
    },
    goDown(index) {
      this.dragging = true;
      let garantia_temp = JSON.parse(
        JSON.stringify(this.garantia.garantias[index + 1])
      );
      this.$set(
        this.garantia.garantias,
        index + 1,
        this.garantia.garantias[index]
      );
      this.$set(this.garantia.garantias, index, garantia_temp);
      this.dragging = false;
    },
    deleteGarantia(index) {
      this.garantia.garantias.splice(index, 1);
    }
  },
  computed: {
    ...mapGetters("personas", ["getGarantias", 'getGarantiasGrupo']),
    garantias() {
      let garantias = null;
      
      if (this.getGarantiasGrupo) {
        garantias = [...this.getGarantiasGrupo];
      } 

      if (this.getGarantias) {
        garantias = [...this.getGarantias];
      }
      
      if (!garantias) return [];
      
      return garantias.filter(
        x => !this.garantia.garantias.some(gar => gar.id === x.id)
      );
    }
  },
  watch: {
    garantia: {
      handler(val) {
        this.$store.dispatch("lineascredito/setDataCreacion", {
          tipo: "garantias",
          data: val
        });
      },
      deep: true
    }
  }
};
</script>
