<template>
  <div>
    <ValidationObserver
      ref="observer"
      v-slot="{ validate }"
      class="w-full text-left"
    >
      <div class="columns is-multiline">
        <div class="column is-6">
          <valid-datepicker
            label-class="mt-4"
            label="Fecha de caducidad"
            :rules="rules_required ? 'required' : 'none'"
            placeholder="seleccionar día..."
            v-model="form.fecha_caducidad"
            position="is-bottom-left"
          />
          <valid-datepicker
            label-class="mt-4"
            label="Fecha máxima de contratación"
            :rules="rules_required ? 'required' : 'none'"
            placeholder="seleccionar día..."
            v-model="form.fecha_maxima_contratacion"
            position="is-bottom-left"
          />
        </div>
        <div class="column is-6">
          <valid-datepicker
            label-class="mt-4"
            label="Fecha máxima para cambiar fuente de fondeo"
            :rules="rules_required ? 'required' : 'none'"
            placeholder="seleccionar día..."
            v-model="form.fecha_maxima_cambio_fuente_fondeo"
            position="is-bottom-left"
          />
          <valid-datepicker
            label-class="mt-4"
            label="Fecha máxima de disposición"
            :rules="rules_required ? 'required' : 'none'"
            placeholder="seleccionar día..."
            v-model="form.fecha_maxima_disposicion"
            position="is-bottom-left"
          />
        </div>
      </div>
      <div v-if="$route.params.idLinea" class="flex justify-end">
        <b-button
          @click="validate().then(result => guardarFechas(result))"
          class="mt-4 is-primary"
          outlined
          >Guardar fechas</b-button
        >
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidDatepicker from "@/components/form/ValidDatepicker";

export default {
  name: "ExtrasFechas",
  props: {
    fechas: Object,
    pasivo: Boolean
  },
  data() {
    return {
      rules_required: false,
      form: {
        fecha_caducidad: null,
        fecha_maxima_cambio_fuente_fondeo: null,
        fecha_maxima_contratacion: null,
        fecha_maxima_disposicion: null
      }
    };
  },
  methods: {
    guardarFechas(validation) {
      if (!validation) {
        return;
      }

      this.$store.dispatch("lineascredito/setFechasIndividual", {
        pasiva: this.pasivo,
        linea_credito_id: this.$route.params.idLinea,
        fecha_caducidad: this.$moment(this.form.fecha_caducidad).format(
          "YYYY-MM-DD"
        ),
        fecha_maxima_cambio_fuente_fondeo: this.$moment(
          this.form.fecha_maxima_cambio_fuente_fondeo
        ).format("YYYY-MM-DD"),
        fecha_maxima_contratacion: this.$moment(
          this.form.fecha_maxima_contratacion
        ).format("YYYY-MM-DD"),
        fecha_maxima_disposicion: this.$moment(
          this.form.fecha_maxima_disposicion
        ).format("YYYY-MM-DD")
      });
    }
  },
  watch: {
    form: {
      handler(val) {
        this.$store.dispatch("lineascredito/setDataCreacion", {
          tipo: "fechas",
          data: val
        });
      },
      deep: true
    }
  },
  components: {
    ValidDatepicker,
    ValidationObserver
  },
  mounted() {
    this.rules_required = true;

    const fechasPropHasValues = Object.values(this.fechas).every(
      fecha => typeof fecha != "undefined" && fecha != null
    );
    if (fechasPropHasValues && Object.values(this.fechas).length > 0) {
      this.form.fecha_caducidad = this.fechas.fecha_caducidad;
      this.form.fecha_maxima_cambio_fuente_fondeo = this.fechas.fecha_maxima_cambio_fuente_fondeo;
      this.form.fecha_maxima_contratacion = this.fechas.fecha_maxima_contratacion;
      this.form.fecha_maxima_disposicion = this.fechas.fecha_maxima_disposicion;
    } else {
      this.form.fecha_caducidad = this.$fecha_cierre;
      this.form.fecha_maxima_cambio_fuente_fondeo = this.$fecha_cierre;
      this.form.fecha_maxima_contratacion = this.$fecha_cierre;
      this.form.fecha_maxima_disposicion = this.$fecha_cierre;
    }
  },
  destroyed() {
    this.rules_required = false;
  }
};
</script>
