<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-6">
        <valid-auto-complete
          label="Buscar y añadir personas"
          placeholder="Buscar personas..."
          icon="search"
          :data="relaciones"
          class="text-left"
          :custom="true"
          :clear-on-select="true"
          :clearable="true"
          show="relacionado.alias"
          @select="setPersona"
          :open-on-focus="true"
          @click.native="checkPersonaId"
        >
          <template v-slot:custom="slotProps">
            <b>{{ slotProps.option.option.relacionado.alias }}</b> -
            {{ slotProps.option.option.rol.nombre }}
          </template>
        </valid-auto-complete>
        <p class="mt-4 font-medium text-lg">Personas añadidas</p>
      </div>
      <div class="column is-12" v-if="personas.length > 0">
        <div class="columns is-multiline">
          <div
            class="column is-3 persona-card"
            v-for="(persona, index) in personas"
            :key="persona.id"
          >
            <persona-card
              :persona="persona"
              :active="activePersona == index"
              :index="index"
              @delete="deletePersona"
              editable
              @set-active="setActive"
            />
          </div>
        </div>
      </div>
      <div class="column is-12" v-else>
        <empty-state
          type="svg"
          :vertical="true"
          imagen="/images/personas_placeholder.svg"
          imgclass="mb-4"
          titulo="No has añadido personas"
          titulo-class="has-text-grey-light text-xl"
          subtitulo="Añade una buscando por el nombre de la persona"
          subtitulo-class="has-text-grey-light"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ValidAutoComplete from "@/components/form/ValidAutoComplete";
import EmptyState from "@/components/generals/EmptyState";
import PersonaCard from "@/components/cards/PersonaCard";
import { mapGetters } from "vuex";
import config from "@/../config";

export default {
  name: "ExtrasPersonasRelacionadas",
  data() {
    return {
      personas: [],
      activePersona: null,
      config: null
    };
  },
  props: {
    personaId: Number
  },
  components: {
    ValidAutoComplete,
    EmptyState,
    PersonaCard
  },
  computed: {
    ...mapGetters("personas", ["getRelaciones", "getRelacionesGrupo"]),
    relaciones() {
      let relaciones = null;
      
      if (this.getRelacionesGrupo) {
        relaciones = [...this.getRelacionesGrupo];
      } 

      if (this.getRelaciones) {
        relaciones = [...this.getRelaciones];
      }
      
      if (!relaciones) return [];
      
      return relaciones.filter(
        x => !this.personas.some(persona => persona.id === x.id)
      );
    }
  },
  methods: {
    setPersona(persona) {
      this.personas.push(persona);
    },
    setActive(index) {
      this.activePersona = index;
    },
    deletePersona(index) {
      this.personas.splice(index, 1);
    },
    checkPersonaId() {
      if (!this.personaId) {
        this.notification(
          "warning",
          "Cliente no seleccionado",
          "Primero debes seleccionar un cliente para ver sus garantías"
        );
      }
    }
  },
  watch: {
    personas(val) {
      this.$store.dispatch("lineascredito/setDataCreacion", {
        tipo: "personas",
        data: val
      });
    }
  },
  created() {
    this.config = config;
  }
};
</script>
