<template>
  <div class="columns is-multiline">
    <div class="column is-6">
      <valid-currency-input
        :rules="`currency|max_value:${capital}`"
        label="Capital del contrato"
        :placeholder="'Introduzca un valor (max: ' + capital + ')...'"
        v-model="form.capital"
        :expanded="true"
      />
      <valid-input
        label="Descripción"
        placeholder="Introduzca una descripción..."
        label-class="mt-4"
        inputclass="has-background-white-ter"
        v-model="form.descripcion"
        type="textarea"
        :expanded="true"
        :rules="rules"
      />
    </div>
    <div class="column is-6">
      <valid-input
        label="Referencia del contrato"
        placeholder="Introduzca la referencia..."
        v-model="form.numero"
        type="text"
        :expanded="true"
        :rules="rules"
      />
      <valid-datepicker
        label-class="mt-4"
        label="Fecha de firma"
        placeholder="seleccionar día..."
        v-model="form.fecha_firma"
        position="is-bottom-left"
        :rules="rules"
      />
      <valid-datepicker
        label-class="mt-4"
        label="Fecha de vencimiento"
        placeholder="seleccionar día..."
        v-model="form.vencimiento_contrato"
        position="is-bottom-left"
        :rules="rules"
        :min-date="
          $moment(form.fecha_firma)
            .add(7, 'days')
            .toDate()
        "
      />
    </div>
    <div class="column is-9">
      <p class="font-bold text-lg">Ciclos del contrato</p>
    </div>
    <div class="column is-3 text-right">
      <b-button
        type="is-primary"
        outlined
        expanded
        @click="isAddingCiclo = true"
        >Agregar ciclo</b-button
      >
    </div>
    <div class="column is-12">
      <div
        class="card has-background-white-ter px-3 py-2 has-border-radius mb-3 shadow-none"
        v-for="(ciclo, index) in form.ciclos"
        :key="index"
      >
        <div class="columns">
          <div class="column is-1">
            <p class="font-bold text-xl">
              {{ index + 1 }}
            </p>
          </div>
          <div class="column">
            Fecha de inicio: {{ ciclo[0] | moment("DD-MM-YYYY") }}
          </div>
          <div class="column">
            Fecha de finalización: {{ ciclo[1] | moment("DD-MM-YYYY") }}
          </div>
          <div class="column is-1">
            <b-icon
              icon="trash"
              class="has-text-danger cursor-pointer"
              @click.native="deleteCiclo(index)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="w-full" v-if="form.ciclos.length == 0">
      <empty-state
        type="svg"
        :vertical="true"
        titulo="No has añadido ningún ciclo"
        titulo-class="has-text-grey-light text-xl"
        subtitulo="Añade ciclos en el botón de arriba"
        subtitulo-class="has-text-grey-light"
      />
    </div>
    <portal to="modalContainer">
      <b-modal
        v-if="isAddingCiclo"
        has-modal-card
        trap-focus
        width="300"
        aria-role="dialog"
        aria-modal
        :active.sync="isAddingCiclo"
      >
        <modal-nuevo-ciclo
          class="modal-has-overflow"
          :cantidadDeCiclos="form.ciclos.length"
          :fechaFirma="form.fecha_firma"
          :fechaVencimiento="form.vencimiento_contrato"
          @added="addCiclo"
        />
      </b-modal>
    </portal>
  </div>
</template>

<script>
import EmptyState from "@/components/generals/EmptyState";
import ModalNuevoCiclo from "@/components/modals/ModalNuevoCiclo";
import ValidCurrencyInput from "@/components/form/ValidCurrencyInput";
import ValidInput from "@/components/form/ValidInput";
import ValidDatepicker from "@/components/form/ValidDatepicker";

export default {
  name: "ExtrasContrato",
  props: {
    pasivo: Boolean,
    capital: Number,
    rules: {
      type: String,
      default: "none"
    }
  },
  data() {
    return {
      isAddingCiclo: false,
      form: {
        capital: null,
        numero: null,
        descripcion: null,
        fecha_firma: null,
        vencimiento_contrato: null,
        ciclos: []
      }
    };
  },
  components: {
    EmptyState,
    ModalNuevoCiclo,
    ValidCurrencyInput,
    ValidInput,
    ValidDatepicker
  },
  computed: {
    ciclos() {
      return this.form.ciclos;
    },
    today() {
      const hoy = new Date();
      return this.$moment(
        `${hoy.getFullYear()}-${hoy.getMonth() + 1}-${hoy.getDate()}`
      ).toDate();
    }
  },
  methods: {
    addCiclo(ciclo) {
      this.form.ciclos.push(ciclo);
    },
    deleteCiclo(index) {
      this.form.ciclos.splice(index, 1);
    },
    isValidDate(d) {
      return d instanceof Date && !isNaN(d);
    }
  },
  watch: {
    form: {
      handler(val) {
        if (!this.isValidDate(val.fecha_firma)) {
          val.fecha_firma = this.today;
        }
        if (!this.isValidDate(val.vencimiento_contrato)) {
          val.vencimiento_contrato = this.today;
        }
        this.$store.dispatch("lineascredito/setDataCreacion", {
          tipo: "contrato",
          data: val
        });
      },
      deep: true
    },
    ciclos(newValue) {
      this.$emit("updatedCiclos", newValue);
    },
    "form.fecha_firma": function(newValue) {
      if (newValue >= this.form.vencimiento_contrato) {
        this.form.vencimiento_contrato = this.$moment(newValue)
          .add(7, "days")
          .toDate();
      }
    }
  },
  mounted() {
    if (!this.form.fecha_firma) {
      this.form.fecha_firma = this.today;
    }

    if (!this.form.vencimiento_contrato) {
      this.form.vencimiento_contrato = this.$moment(this.today)
        .add(7, "days")
        .toDate();
    }

    if (this.capital && this.form.capital === null) {
      this.form.capital = this.capital;
    }
  }
};
</script>
