<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
  >
    <div class="modal-card" style="width: 450px">
      <section class="modal-card-head pb-0 flex-col items-start">
        <button
          type="button"
          class="delete absolute right-0 mr-5"
          @click="$parent.close()"
        />
        <p class="font-bold text-2xl">
          Agregar ciclo
        </p>
        <p class="text-sm mt-0">Ciclo {{ cantidadDeCiclos + 1 }}</p>
      </section>
      <section class="modal-card-body has-background-white text-left">
        <!-- <valid-datepicker
          label-class="font-light has-text-grey-light"
          label="Fecha de inicio y fin"
          rules="required"
          placeholder="seleccionar día..."
          v-model="dates"
          :range="true"
          position="is-bottom-left"
          :formatter="rangeFormatter"
        /> -->
        <valid-datepicker
          label-class="mt-4"
          label="Fecha de inicio"
          placeholder="seleccionar día..."
          v-model="fecha_inicio"
          position="is-bottom-left"
          rules="required"
          :min-date="fechaFirma"
          :max-date="
            $moment(fechaVencimiento)
              .subtract(1, 'days')
              .toDate()
          "
        />
        <valid-datepicker
          label-class="mt-4"
          label="Fecha de fin"
          placeholder="seleccionar día..."
          v-model="fecha_final"
          position="is-bottom-left"
          rules="required"
          :min-date="
            $moment(fecha_inicio)
              .add(1, 'days')
              .toDate()
          "
          :max-date="fechaVencimiento"
        />
      </section>
      <section class="modal-card-foot justify-end">
        <b-button
          :type="'is-primary'"
          @click="validate().then(result => setData(result))"
        >
          Agregar ciclo
        </b-button>
      </section>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidDatepicker from "@/components/form/ValidDatepicker";

export default {
  name: "ModalNuevoCiclo",

  data() {
    return {
      dates: [],
      fecha_inicio: this.fechaFirma,
      fecha_final: this.$moment(this.fechaFirma)
        .add(1, "days")
        .toDate()
    };
  },
  components: {
    ValidDatepicker,
    ValidationObserver
  },
  props: {
    requisito: {
      type: Object,
      required: false
    },
    cantidadDeCiclos: Number,
    fechaFirma: Date,
    fechaVencimiento: Date
  },
  methods: {
    setData(res) {
      if (!res) return;
      if (this.fecha_inicio > this.fecha_final) {
        this.notification(
          "warning",
          "Introduce fechas validas",
          "La fecha de inicio no puede ser despues de la fecha final"
        );
        return;
      }
      this.dates.push(this.fecha_inicio, this.fecha_final);
      this.$emit("added", this.dates);
      this.$parent.close();
    },
    rangeFormatter(date) {
      return (
        "" +
        this.$moment(date[0]).format("DD/MM/YYYY") +
        " - " +
        this.$moment(date[1]).format("DD/MM/YYYY")
      );
    }
  },
  watch: {
    fecha_inicio(newValue) {
      if (newValue > this.fecha_final) {
        this.fecha_final = this.$moment(newValue)
          .add(1, "days")
          .toDate();
      }
    }
  }
};
</script>
