<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
  >
    <div class="modal-card" style="width: 450px">
      <section class="modal-card-head pb-0">
        <p class="font-bold text-2xl">
          {{ requisito ? "Ver requisito" : "Agregar requisito" }}
        </p>
        <button
          type="button"
          class="delete absolute right-0 mr-5"
          @click="$parent.close()"
        />
      </section>
      <section class="modal-card-body has-background-white text-left">
        <valid-input
          rules="required"
          label="Nombre del requisito"
          label-class="has-text-grey-light font-light"
          placeholder="Introduzca un nombre..."
          v-model="form.nombre"
          :disabled="requisito ? true : false"
          type="text"
          :expanded="true"
        />
        <valid-select
          rules="required"
          label="Tipo de requisito"
          placeholder="Selecciona uno..."
          v-model="form.tipo_requisito_id"
          label-class="has-text-grey-light font-light"
          :data="getTiposRequisito"
          :disabled="requisito ? true : false"
          show="nombre"
          :expanded="true"
          realValue="id"
        />
        <valid-input
          rules="required"
          label="Descripción"
          placeholder="Introduzca una descripción..."
          inputclass="has-background-white-ter"
          label-class="has-text-grey-light font-light"
          :disabled="requisito ? true : false"
          v-model="form.descripcion"
          type="textarea"
          :expanded="true"
        />
      </section>
      <section class="modal-card-foot justify-end">
        <b-button
          v-if="!requisito"
          :type="'is-primary'"
          @click="validate().then(result => setData(result))"
        >
          Agregar nuevo requisito
        </b-button>
        <b-button @click="$parent.close()">
          Cerrar
        </b-button>
      </section>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidSelect from "@/components/form/ValidSelect";
import ValidInput from "@/components/form/ValidInput";
import { mapGetters } from "vuex";

export default {
  name: "ModalNuevoRequisito",

  data() {
    return {
      form: {
        nombre: null,
        tipo_requisito_id: null,
        descripcion: null,
        checked: true
      }
    };
  },
  components: {
    ValidationObserver,
    ValidSelect,
    ValidInput
  },
  props: {
    requisito: {
      type: Object,
      required: false
    }
  },
  computed: {
    ...mapGetters("catalogos", ["getTiposRequisito"])
  },
  methods: {
    setData(res) {
      if (!res) return;
      this.$emit("added", this.form);
      this.$parent.close();
    }
  },
  mounted() {
    if (this.getTiposRequisito == null) {
      this.$store.dispatch("catalogos/getTiposRequisito");
    }
    if (this.requisito) {
      this.form.nombre = this.requisito.nombre;
      this.form.tipo_requisito_id = this.requisito.tipo_requisito_id;
      this.form.descripcion = this.requisito.descripcion;
    }
  }
};
</script>
